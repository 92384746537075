import request from '@/utils/request';
export var defaultCityData = {
  id: 0,
  enabled: true,
  stateId: undefined,
  countryId: undefined,
  name: '',
  country: {
    id: undefined
  },
  state: {
    id: undefined
  }
};
export var getCities = function getCities(params) {
  return request({
    url: '/cities',
    method: 'get',
    params: params
  });
};
export var getCityById = function getCityById(id) {
  return request({
    url: "/cities/".concat(id),
    method: 'get'
  });
};
export var updateCity = function updateCity(id, data) {
  return request({
    url: "/cities/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteCity = function deleteCity(id) {
  return request({
    url: "/cities/".concat(id),
    method: 'delete'
  });
};
export var createCity = function createCity(data) {
  return request({
    url: '/cities/',
    method: 'post',
    data: data
  });
};